/*
 * 23-01-2022 MDA-363 LuânĐT login with google authen
 */
@mixin bg($url) {
  background-attachment: fixed;
  background-image: url(#{$url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@mixin col($c, $m: 0px) {
  $margin: calc(((12 / #{$c}) - 1) * #{$m});
  width: calc(((100% - #{$margin}) / 12) * #{$c});
  &:not(:last-child) {
    margin: 0 #{$m} 0 0;
  }
}

html,
body {
  font: 400 15px "Didact Gothic", sans-serif;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

body {
  @include bg(
    // "https://static.pexels.com/photos/4827/nature-forest-trees-fog.jpeg"
    "https://r73troypb4obj.vcdn.cloud/picture/report_images/report_auth-login-background1.1.png"
  );
  position: relative;
}

a {
  color: #ae9a51;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
label {
  display: block;
}
input {
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: block;
  outline: none;
  padding: 0 8px 8px 30px;
  position: relative;
  transition: all 0.2s ease-in-out;
  width: 100%;
  &:focus {
    border-bottom-color: #0c0d1f;
  }
}
.row {
  &::after {
    clear: both;
    content: "";
    display: block;
    width: 100%;
  }
}
.col {
  border-radius: inherit;
  float: left;
  margin: 0;
  padding: 30px 50px;
  position: relative;
  &.col-6 {
    @include col(6);
  }
}
.container {
}
.login-box {
  backface-visibility: hidden;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.4), 0 0 0 2000px rgba(#0c0d1f, 0.3);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0) scale(1, 1);
  width: 90%;
  max-width: 1000px;
}
.login-title {
  background: transparent;
  text-shadow: 0 0 15px rgba(255, 255, 255, 1);
  width: 60%;
}
h1 {
  font: 48px/50px "Semibold SF Compact Rounded", sans-serif;
  &.author {
    font-size: 30px;
    line-height: 32px;
    font-style: italic;
    text-align: center;
  }
}
.small-text {
  font-size: 22px;
}
.login-form {
  background: #f9f0ea;
  text-align: center;
  width: 40%;
  > form {
    > *:not(:last-child) {
      margin: 0 0 20px;
    }
  }
}
.avatar {
  background: rgba(#fff, 1);
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  height: 100px;
  margin: 0 auto 30px;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 100px;
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  &::after,
  &::before {
    border-radius: 50%;
    color: #ae9a51;
    content: "";
    left: 50%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    z-index: 1;
  }
  &::after {
    border: 3px solid;
    height: 32px;
    top: 36%;
    width: 32px;
  }
  &::before {
    border: 3px solid transparent;
    border-top: 3px solid;
    border-right: 3px solid;
    height: 48px;
    top: 73%;
    width: 48px;
  }
  img {
    border-radius: inherit;
    height: 100%;
    object-fit: cover;
    position: relative;
    width: 100%;
    z-index: 2;
  }
}
.registration {
  margin: 0 0 20px;
}
.login,
.passwd {
  position: relative;
  &::after {
    backface-visibility: hidden;
    color: rgba(0, 0, 0, 0.2);
    display: block;
    font: 400 16px "FontAwesome", sans-serif;
    left: 14px;
    position: absolute;
    top: 35%;
    transform: translate3d(-50%, -50%, 0) scale(1);
    z-index: 1;
  }
}
.login {
  &::after {
    content: "\f2be";
  }
}
.passwd {
  &::after {
    content: "\f084";
  }
}
.button {
  border: none;
  background: #ae9a51;
  color: #f9f0ea;
  cursor: pointer;
  display: block;
  padding: 12px;
  width: 100%;
}
.lost-passwd {
  margin: 30px 0 0;
}
.checkbox {
  width: 16px;
  height: 16px;
  background-image: url("https://r73troypb4obj.vcdn.cloud/picture/icons/login_check_box_inactive.svg");
  background-repeat: no-repeat;
  &.active {
    background-image: url("https://r73troypb4obj.vcdn.cloud/picture/icons/login_check_box_active.svg");
  }
}
.show-password {
  display: flex;
  span {
    margin-left: 15px;
  }
}
.lika-login-btn {
  display: flex;
  align-items: center;
  outline: 0 !important;
  box-shadow: none !important;
  border: 0px !important;
  justify-content: center;
  width: 100%;
  background-color: #dfdfdf;
  opacity: 1;
  height: 40px;
  border-radius: 8px;
  margin-bottom: 30px;
  &.active {
    background-color: #ff8805;
    &:hover {
      background-color: #faa541;
    }
  }
  span {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
  }
}

.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  cursor: pointer;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
